import React from "react";

const MainText = () => {
    return(
        <div className="mainText">
            Hi,I'm Brian!
        </div>
    )
}

export default MainText